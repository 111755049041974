import React from 'react';
import Breadcrumb from 'src/components/Breadcrumb/Breadcrumb';
import Layout from '../../components/Layout/Layout';
import CompanyIntro from '../../components/About/Intro';
import CompanyPromoTop from '../../components/About/PromoTop';
import Navigation from '../../components/About/Navigation';
import TopBanner from '../../components/About/TopBanner';

import { SITE_METADATA } from '../../constants';

const BREADCRUMB_LEVELS = [
  {
    title: 'トップ',
    path: '/',
  },
  {
    title: '会社概要',
    path: '/about/',
  },
];

const CompanyIndexPage = () => {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.about.title,
        description: SITE_METADATA.about.description,
      }}
    >
      <TopBanner />
      <Breadcrumb customLevels={BREADCRUMB_LEVELS} />
      <CompanyIntro />
      <Navigation />
      <CompanyPromoTop isAbout />
    </Layout>
  );
};

export default CompanyIndexPage;
