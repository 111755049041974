// @flow
import React from 'react';
import { Container } from '@material-ui/core';
import styles from './Intro.module.scss';

export default () => (
  <section className={styles.section}>
    <Container className={styles.container}>
      <div>
        <p className={styles.headline}>ミッション</p>
        <h1 className={styles.boldText}>
          お買いものに「めんどくさい」はいらない。
        </h1>
      </div>
      <div className={styles.blockText2}>
        <p className={styles.headline}>パーパス</p>
        <h1 className={styles.boldText}>
          夢に自信を、心に余裕を持てる世界を作る。
        </h1>
      </div>
    </Container>
  </section>
);
