import React from 'react';
import styles from './TopBanner.module.scss';

const TopBanner = () => (
  <section className={styles.container}>
    <h1 className={styles.title}>会社概要</h1>
  </section>
);

export default TopBanner;
