import React from 'react';
import loadable from '@loadable/component';
import Box from '@material-ui/core/Box';

const CompanyTeam = loadable(() => import(/* webpackPreload: true */ './Team'));
const InfoTabs = loadable(() => import(/* webpackPreload: true */ './Info'));

const Navigation = () => {
  return (
    <Box bgcolor="#fafafa">
      <CompanyTeam />
      <InfoTabs />
    </Box>
  );
};

export default Navigation;
